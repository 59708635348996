import frontendImage from './images/frontend.png';
import backendImage from './images/backend.png';
import databaseImage from './images/database.png';

import portfolioV2 from './images/portfolio-v2.png';
import askSkift from './images/ask-skift.png';
import st200 from './images/st200.jpg';
import studybud from './images/studybud.png';
import tippie from './images/tippie.png';
import zoomies from './images/zoomies.png';
import zoomiesV2 from './images/zoomies-v2.png';

import javascript from './images/javascript.png';
import python from './images/python.png';
import typescript from './images/typescript.png';
import ruby from './images/ruby.png';
import nodejs from './images/nodejs.png';

export const navItems = [
    {
        path: '#home',
        name: 'home'
    },
    {
        path: '#about-me',
        name: 'about me'
    },
    {
        path: '#skills',
        name: 'skills'
    },
    {
        path: '#projects',
        name: 'projects'
    },
    {
        path: '#contact-me',
        name: 'contact me'
    }
];

export const socials = [
    {
        name: 'GitHub',
        link: 'https://github.com/reitoserizawa',
        icon: 'fa-brands fa-github'
    },
    {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/reitos/',
        icon: 'fa-brands fa-linkedin-in'
    },
    {
        name: 'Mail',
        link: 'mailto: reitoserizawa@gmail.com',
        icon: 'fa-solid fa-envelope'
    }
];

export const aboutMeTags = [
    {
        name: 'hobbies',
        paragraph: 'Outside of coding, I love:',
        tags: ['Board games', 'TV games', 'Anime', 'Acoustic guitar']
    },
    {
        name: 'studying',
        paragraph: 'I am currently working on:',
        tags: ['AWS Certification', 'Progressive Web Apps', 'Java']
    }
];

export const skillItems = [
    {
        name: 'Frontend',
        color: '#78b5d5',
        image: frontendImage,
        icon: 'fa-regular fa-window-restore',
        skills: ['JavaScript', 'React', 'Redux', 'HTML', 'CSS', 'Styled Component', 'Tailwind CSS', 'Bootstrap']
    },
    {
        name: 'Backend',
        color: '#ea4c89',
        image: backendImage,
        icon: 'fa-solid fa-desktop',
        skills: ['Python', 'Django', 'Flask', 'Node.js', 'TypeScript', 'Express', 'Ruby', 'Ruby on Rails']
    },
    {
        name: 'DBs & Others',
        color: '#f36621',
        image: databaseImage,
        icon: 'fa-solid fa-database',
        skills: ['SQL', 'MongoDB', 'PostgreSQL', 'Prisma', 'Mongoose', 'AWS', 'Langchain', 'GPT']
    }
];

export const keySkills = [
    { name: 'JavaScript', logo: javascript },
    { name: 'Python', logo: python },
    { name: 'TypeScript', logo: typescript },
    { name: 'Node.js', logo: nodejs },
    { name: 'Ruby', logo: ruby }
];

export const skillMacWindowItems = [
    {
        icon: 'fa-solid fa-comments',
        title: 'Effective Communicator',
        paragraph: "Since working in the sales position, Reito has focused on effective communication with the clients. As a software engineer, Reito uses his skills to communicate about the project's needs, deadlines, and expectations."
    },
    {
        icon: 'fa-solid fa-code',
        title: 'Self-improver',
        paragraph: 'Reito keeps improving his technical skills by continuously studying new technologies. Reito utilizes resources such as books, YouTube, and Udemy to learn them.'
    },
    {
        icon: 'fa-regular fa-handshake',
        title: 'Quick Adapter',
        paragraph: 'Reito studied abroad in different countries when he was younger. He learned how to adapt quickly in a different setting from the experience, and he can quickly adapt to new technologies and environments.'
    }
];

export const projectLinks = [
    {
        name: 'Live',
        icon: 'fa-regular fa-window-maximize'
    },
    {
        name: 'Article',
        icon: 'fa-solid fa-newspaper'
    },
    {
        name: 'Demo',
        icon: 'fa-solid fa-video'
    },
    {
        name: 'Code',
        icon: 'fa-brands fa-github'
    }
];

export const projects = [
    {
        title: 'Zoomies v2',
        image: zoomiesV2,
        color: '#f7f7f7',
        date: 'Jan, 2025',
        links: [
            {
                name: 'Code',
                link: 'https://github.com/reitoserizawa/zoomies-v2'
            }
        ],
        technologies: ['JavaScript', 'TypeScript', 'React', 'Redux', 'Node,js', 'Express', 'Prisma', 'Docker', 'PostgreSQL', 'HTML', 'CSS', 'Styled Component'],
        description: 'Zoomies v2 is an updated version of Zoomies. The entire code is updated and addd new features in addition to object oriented programming.',
        tag: 'Personal'
    },
    {
        title: 'Portfolio v2',
        image: portfolioV2,
        color: '#282c34',
        date: 'Mar, 2024',
        links: [
            {
                name: 'Live',
                link: 'https://www.reito.me/'
            },
            {
                name: 'Code',
                link: 'https://github.com/reitoserizawa/portfolio-v2'
            }
        ],
        technologies: ['JavaScript', 'React', 'HTML', 'CSS', 'Styled Component'],
        description: 'Portfolio v2 is a portfolio website. It is a simple and clean website that showcases projects and skills. The website is built using React and styled components. The website is also responsive and mobile-friendly.',
        tag: 'Personal'
    },
    {
        title: 'Skift Travel 200',
        image: st200,
        color: '#2886d0',
        date: 'Sep, 2023',
        links: [
            {
                name: 'Live',
                link: 'https://data.skift.com/skift-travel-200/'
            },
            {
                name: 'Article',
                link: 'https://skift.com/2023/09/20/skift-travel-200-the-definitive-way-to-track-travel-stocks/'
            }
        ],
        technologies: ['JavaScript', 'React', 'Redux', 'Node.js', 'Express', 'MongoDB', 'HTML', 'CSS', 'Tailwind CSS'],
        description: 'Skift Travel 200 is an application that provides stock index numbers that represent the performance of the global universe of public companies. For now, it tracks 196 public companies from 34 countries, divided into five regions.',
        tag: 'Professional'
    },
    {
        title: 'Ask Skift',
        image: askSkift,
        color: '#F3F6F9',
        date: 'May, 2023',
        links: [
            {
                name: 'Live',
                link: 'https://ask.skift.com/'
            },
            {
                name: 'Article',
                link: 'https://skift.com/ask-skift-faq/'
            }
        ],
        technologies: ['JavaScript', 'React', 'Redux', 'Python', 'Flask', 'MongoDB', 'Langchain', 'GPT-3.5', 'HTML', 'CSS', 'Styled Component'],
        description: 'Ask Skift is an AI chatbot answering engine specializing in the travel industry. It is here to be of service for your daily work queries by using GPT-3.5. Langchain is used to connect with the database.',
        tag: 'Professional'
    },
    {
        title: 'Studybud',
        image: studybud,
        color: '#3F4155',
        date: 'Nov, 2022',
        links: [
            {
                name: 'Code',
                link: 'https://github.com/reitoserizawa/python-django-studybud-project?tab=readme-ov-file'
            },
            {
                name: 'Demo',
                link: 'https://www.youtube.com/watch?v=e_fCTxD2i-c'
            }
        ],
        technologies: ['JavaScript', 'Python', 'Django', 'SQLite', 'HTML', 'CSS'],
        description: 'Studybud is a chat application created with Python and Django. You can create your own profile with an avatar, make a chat room, and chat in the room. You can also update the room, delete the chat, and filter by topic or keyword.',
        tag: 'Personal'
    },
    {
        title: 'Tippie',
        image: tippie,
        color: '',
        date: 'Jul, 2022',
        links: [
            {
                name: 'Code',
                link: 'https://github.com/reitoserizawa/Tippie'
            },
            {
                name: 'Demo',
                link: 'https://youtu.be/mhtTPhMhHZs'
            }
        ],
        technologies: ['JavaScript', 'React', 'Ruby', 'Ruby on Rails', 'PostgreSQL', 'HTML', 'CSS', 'Bootstrap', 'Yelp API'],
        description: 'Tippie is a reviewing application for restaurants and servers that allows users to get to know about the restaurant and server, and tip them. You can create a profile, search for restaurants, and leave a review for the restaurant and server.',
        tag: 'Personal'
    },
    {
        title: 'Zoomies',
        image: zoomies,
        color: '#b4ac5c',
        date: 'Jun, 2022',
        links: [
            {
                name: 'Code',
                link: 'https://github.com/reitoserizawa/Zoomies'
            },
            {
                name: 'Demo',
                link: 'https://youtu.be/U0r333t5CPE'
            }
        ],
        technologies: ['JavaScript', 'React', 'Ruby', 'Ruby on Rails', 'PostgreSQL', 'HTML', 'CSS', 'Bootstrap'],
        description: 'Zoomies is a dog park application. You can create a profile of yourself and pet dogs, and check in and out at dog parks in NYC. You can also see dogs that are checked in at each dog park with profiles and how busy the dog park is.',
        tag: 'Personal'
    }
];
